import React, { useState, useEffect } from "react";
import { Trash2 } from "lucide-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../Loader/Loader";
import cartEmpty from "../../Assets/images/empty-cart.png";
import { useParams } from "react-router-dom";
import LocationButton from "./GetAddress";

// Skeleton loader component
const SkeletonLoader = ({ type }) => {
  switch (type) {
    case "cart-item":
      return (
        <div className="flex items-center space-x-4 border-b border-gray-300 pb-4 animate-pulse shadow-lg">
          <div className="w-32 h-32 bg-gray-200 rounded p-6 "></div>
          <div className="flex-1 space-y-4">
            <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
            <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
            <div className="h-4 w-2/3 bg-gray-200 rounded"></div>
            <div className="flex items-center justify-between">
              <div className="h-6 w-16 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      );
    case "price-summary":
      return (
        <div className="shadow-lg border-gray-300 p-4">
          <div className="animate-pulse">
            <div className="h-6 w-3/4 bg-gray-200 rounded mb-4"></div>
            <div className="h-4 w-full bg-gray-200 rounded mb-3"></div>
            <div className="h-4 w-full bg-gray-200 rounded mb-3"></div>
            <div className="h-4 w-full bg-gray-200 rounded mb-3"></div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

const BuyNowPage = (props) => {
  const navigate = useNavigate();
  const { startLoader, stopLoader } = useLoader();
  const [paymentMethod, setPaymentMethod] = useState("Online");
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [address, setAddress] = useState({
    fullName: "",
    phone: "",
    addressLine: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const { productId } = useParams();

  const [savedAddresses, setSavedAddresses] = useState([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  //  const [isLoading, setIsLoading] = useState(true);
  const [addressOption, setAddressOption] = useState("existing");
  const [showSaveButton, setShowSaveButton] = useState(false); // Show Save Address button only for "Add New Address"
  const [showFindMeButton, setShowFindMeButton] = useState(false); // Initially hide the FIND ME button

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newAddressData, setNewAddressData] = useState({
    fullName: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddressData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveEdit = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));

    try {
      const updatedAddresses = [...savedAddresses];
      updatedAddresses[editingIndex] = newAddressData;

      // Update via API
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/address/${storedUserData._id}/${savedAddresses[editingIndex]._id}`,
        newAddressData
      );

      if (response.data.success) {
        setSavedAddresses(updatedAddresses);
        localStorage.setItem("addresses", JSON.stringify(updatedAddresses));
        setIsModalOpen(false);
        setIsEditing(false);
        setNewAddressData({
          fullName: "",
          phoneNumber: "",
          address: "",
          city: "",
          state: "",
          postalCode: "",
        });
        alert("Address updated successfully");
      } else {
        alert("Failed to update address");
      }
    } catch (error) {
      console.error("Error updating address:", error);
      alert("Error updating address");
    }
  };

  const handleAddNewAddress = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));

    try {
      // Add new address via API
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/address/${storedUserData._id}`,
        newAddressData
      );

      if (response.data.success) {
        const updatedAddresses = [...savedAddresses, response.data.data];
        setSavedAddresses(updatedAddresses);
        localStorage.setItem("addresses", JSON.stringify(updatedAddresses));
        setIsModalOpen(false);
        setNewAddressData({
          fullName: "",
          phoneNumber: "",
          address: "",
          city: "",
          state: "",
          postalCode: "",
        });
        alert("Address added successfully");
      } else {
        alert("Failed to add address");
      }
    } catch (error) {
      console.error("Error adding address:", error);
      alert("Error adding address");
    }
  };

  useEffect(() => {
    fetchCartData();
    console.log("Product ID from URL:", productId);
  }, [productId]);

  // Load saved addresses from localStorage
  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));

    if (storedUserData && storedUserData.isLoggedIn) {
      // Fetch all addresses from the API
      axios
        .get(`https://avantdivine.com/api/address/${storedUserData._id}`)
        .then((response) => {
          if (response.data.success) {
            const fetchedAddresses = response.data.data; // All addresses
            console.log("Fetched Addresses:", fetchedAddresses);
            setSavedAddresses(fetchedAddresses);
            if (fetchedAddresses.length > 0) {
              setSelectedAddressIndex(0); // Set default selected address
              setAddress(fetchedAddresses[0]); // Set address details for the first saved address
            }
          } else {
            console.error("Failed to fetch addresses:", response.data.message);
          }
        })
        .catch((error) => {
          console.error(
            "Error fetching addresses:",
            error.response || error.message
          );

          // Fallback to localStorage if API call fails
          const savedAddresses =
            JSON.parse(localStorage.getItem("addresses")) || [];
          setSavedAddresses(savedAddresses);
          if (savedAddresses.length > 0) {
            setSelectedAddressIndex(0); // Set default selected address
            setAddress(savedAddresses[0]); // Set address details for the first saved address
          }
        });
    } else {
      console.error("User not logged in or missing user data.");

      // Fallback to localStorage if user is not logged in
      const savedAddresses =
        JSON.parse(localStorage.getItem("addresses")) || [];
      setSavedAddresses(savedAddresses);
      if (savedAddresses.length > 0) {
        setSelectedAddressIndex(0); // Set default selected address
        setAddress(savedAddresses[0]); // Set address details for the first saved address
      }
    }

    // Fetch cart data
    fetchCartData();
  }, []);
  useEffect(() => {
    // Hide the "Save Address" button when switching back to "existing" address option
    if (addressOption === "existing") {
      setShowSaveButton(false);
      setShowFindMeButton(false); // Hide FIND ME button when switching to existing
    }
  }, [addressOption]);

  const fetchCartData = async () => {
    setIsLoading(true); // Ensure loading starts
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData && storedUserData.isLoggedIn) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/cart/user/${storedUserData._id}`
        );
        if (response.data.status) {
          const items = response.data.data.cart.items;
          const filteredItems = items.filter(
            (item) => item.productId._id === productId
          ); // Filter by productId from URL
          setCartItems(filteredItems);

          // Calculate total price based on the filtered "Buy Now" items
          const newTotalPrice = filteredItems.reduce(
            (acc, item) => acc + item.quantity * item.productId.price,
            0
          );
          setTotalPrice(newTotalPrice); // Set the total price for the "Buy Now" product

          let count = filteredItems.reduce(
            (sum, item) => sum + item.quantity,
            0
          );
          props.setCartCount(count);
        }
      } catch (error) {
        console.error("Error fetching cart data:", error);
      } finally {
        setIsLoading(false); // Stop loading after fetching data
      }
    }
  };

  const updateQuantity = async (productId, itemId, action) => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      const userId = storedUserData?._id;
      await axios.put(
        `${process.env.REACT_APP_API_URL}/cart/update/${itemId}`,
        {
          userId,
          productId,
          action,
        }
      );
      fetchCartData(); // Re-fetch data after updating quantity
    } catch (error) {
      console.error(`Error in ${action}Quantity:`, error);
    }
  };

  const deleteCartItem = async (itemId) => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      const userId = storedUserData?._id;
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/cart/delete/${itemId}`,
        { data: { userId } }
      );
      fetchCartData(); // Re-fetch data after deleting item
    } catch (error) {
      console.error("Error deleting cart item:", error);
    }
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const saveAddressesToLocalStorage = () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));

    const addressData = {
      fullName: address.fullName,
      phoneNumber: address.phone,
      address: address.addressLine,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
    };

    if (addressOption === "new") {
      // Creating a new address (POST request to http://localhost:5000/api/address/userId)
      axios
        .post(
          `https://avantdivine.com/api/address/${storedUserData._id}`,
          addressData
        )
        .then((response) => {
          if (response.data.success) {
            const updatedAddresses = [...savedAddresses, response.data.data]; // Assuming response.data.data contains the new address
            localStorage.setItem("addresses", JSON.stringify(updatedAddresses));
            setSavedAddresses(updatedAddresses); // Update state with the new list of addresses
            setShowSaveButton(false); // Hide Save Address button after saving
            setAddress({
              // Clear address form
              fullName: "",
              phone: "",
              addressLine: "",
              city: "",
              state: "",
              postalCode: "",
            });
            alert(" Address Save");
          } else {
            alert("Failed to save address.");
          }
        })
        .catch((error) => {
          console.error("Error saving new address:", error);
          alert("Error saving new address.");
        });
    } else if (addressOption === "existing") {
      // Editing an existing address (PUT request to http://localhost:5000/api/address/userId/AddressId)
      if (selectedAddressIndex === null) return; // No address selected for editing

      const selectedAddressId = savedAddresses[selectedAddressIndex]._id;

      axios
        .put(
          `https://avantdivine.com/api/address/${storedUserData._id}/${selectedAddressId}`,
          addressData
        )
        .then((response) => {
          if (response.data.success) {
            const updatedAddresses = [...savedAddresses];
            updatedAddresses[selectedAddressIndex] = response.data.data; // Replace the edited address in the list
            localStorage.setItem("addresses", JSON.stringify(updatedAddresses));
            setSavedAddresses(updatedAddresses); // Update state with the edited address
            setShowSaveButton(false); // Hide Save Address button after saving
            setAddress({
              // Clear address form
              fullName: "",
              phone: "",
              addressLine: "",
              city: "",
              state: "",
              postalCode: "",
            });
          } else {
            alert("Failed to update address.");
          }
        })
        .catch((error) => {
          console.error("Error editing address:", error);
          alert("Error editing address.");
        });
    }
  };
  const handleAddressSelection = (index) => {
    setSelectedAddressIndex(index);
    setAddress(savedAddresses[index]);
  };

  const handleEditAddress = (index) => {
    setSelectedAddressIndex(index);
    setAddress(savedAddresses[index]);
    setAddressOption("new"); // Switch to the "Add New Address" mode to allow editing
    setShowSaveButton(true); // Show Save Address button when editing
  };

  const handleDeleteAddress = (index) => {
    const updatedAddresses = savedAddresses.filter((_, i) => i !== index);
    localStorage.setItem("addresses", JSON.stringify(updatedAddresses));
    setSavedAddresses(updatedAddresses);
    if (selectedAddressIndex === index) {
      setSelectedAddressIndex(null);
      setAddress({
        fullName: "",
        phoneNumber: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
      });
    }
  };

  const initiateOrder = async () => {
    console.log("Clicked");

    if (paymentMethod === "Online") {
      initiatePhonePayPayment();
    } else if (paymentMethod === "cod") {
      initiateCOD();
    }
  };

  const initiateCOD = async () => {
    console.log("Initiating Cash on Delivery...");
    startLoader();

    setTimeout(() => {
      stopLoader();
      navigate("/successfulpayment");
    }, 3000);
  };

  const initiatePhonePayPayment = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    console.log("Clicked" + address.phoneNumber);
    console.log("Clicked" + address.address);

    // Check if address object and all fields are valid (use optional chaining)
    if (
      !address?.fullName?.trim() ||
      !address?.phoneNumber?.trim() ||
      !address?.address?.trim() ||
      !address?.city?.trim() ||
      !address?.state?.trim() ||
      !address?.postalCode?.trim()
    ) {
      alert("Please fill in all address fields.");
      return;
    }

    // Phone validation
    if (!/^\d{10}$/.test(address.phoneNumber)) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }

    // Postal code validation
    if (!/^\d{6}$/.test(address.postalCode)) {
      alert("Please enter a valid 6-digit postal code.");
      return;
    }

    try {
      // Prepare the items array from cartItems
      const items = cartItems.map((item) => ({
        productId: item.productId._id,
        quantity: item.quantity,
        price: item.productId.price,
        size: item.size,
      }));

      const requestData = {
        transactionId: `txn${Date.now()}`, // Unique transaction ID
        amount: totalPrice, // Total price from the cart
        userId: storedUserData._id, // User ID from localStorage
        address: {
          fullName: address.fullName,
          phoneNumber: address.phoneNumber,
          address: address.address,
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
        },
        items: items, // Dynamically include cart items
        paymentMethod: paymentMethod, // Payment method (Online or cod)
      };

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/payment/new-payment`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "X-MERCHANT-ID": process.env.MERCHANT_ID,
        },
        data: JSON.stringify(requestData),
      };

      const response = await axios.request(config);

      if (response.data.success) {
        const redirectUrl =
          response.data.data?.data?.instrumentResponse?.redirectInfo?.url;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          alert("Redirect URL is missing or invalid.");
        }
      } else {
        alert("Order creation failed.");
      }
    } catch (error) {
      alert("Error initiating payment.");
    }
  };
  const handlePaymentCallback = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const transactionId = urlParams.get("transactionId");

    if (transactionId) {
      checkPaymentStatus(transactionId);
    } else {
      alert("Invalid callback URL.");
    }
  };

  const checkPaymentStatus = async (transactionId) => {
    try {
      const statusApiUrl = `${process.env.REACT_APP_API_URL}/payment/status?id=${transactionId}`;
      const response = await axios.get(statusApiUrl);

      if (response.data.success) {
        alert("Payment successful.");
      } else {
        alert("Payment failed.");
      }
    } catch (error) {
      alert("Error checking payment status.");
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <div className="container text-center mt-20 mb-20">
            <img
              src={cartEmpty}
              className="mx-auto mb-4"
              alt="Cart Empty Icon"
              style={{ height: "auto", width: "100px" }}
            />
            <p className="text-xl font-semibold mb-2">Loading...</p>
          </div>
        ) : cartItems.length === 0 ? (
          <div className="container text-center mt-20 mb-20">
            <img
              style={{ height: "auto", width: "100px" }}
              src={cartEmpty}
              className="mx-auto mb-4"
              alt="Cart Empty Icon"
            />
            <p className="text-xl font-semibold mb-2">
              You have no items to check out. Please add products to your cart
              before proceeding.
            </p>
            <button
              className="py-3 px-6 bg-black text-white rounded-md hover:bg-gray-800"
              onClick={() => (window.location.href = "/shop")}
            >
              Shop Now
            </button>
          </div>
        ) : (
          <div className="min-h-screen bg-gray-100">
            <div className="container mx-auto py-10 flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-10 justify-center">
              <div className="flex-1 p-6 bg-white shadow-lg rounded-lg flex flex-col sm:w-1/2">
                <div className="flex-1">
                  {cartItems.map((item) => (
                    <div
                      key={item._id}
                      className="flex items-center space-x-4 border-b border-gray-300 pb-4"
                    >
                      <div>
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}/${item.productId.images[0]}`}
                          alt={item.productId.name}
                          className="object-cover rounded mt-3"
                          style={{ height: "135px", width: "135px" }}
                        />
                        <div className="flex items-center justify-center mt-2 gap-2">
                          <button
                            onClick={() =>
                              updateQuantity(
                                item.productId._id,
                                item._id,
                                "decrement"
                              )
                            }
                            className="px-4 py-2 bg-black text-white rounded-lg"
                          >
                            -
                          </button>
                          <div className="px-4 py-2 border border-gray-300 rounded-md">
                            <p className="text-lg">{item.quantity}</p>
                          </div>
                          <button
                            onClick={() =>
                              updateQuantity(
                                item.productId._id,
                                item._id,
                                "increment"
                              )
                            }
                            className="px-4 py-2 bg-black text-white rounded-md"
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="flex-1">
                        <h3 className="text-lg font-medium">
                          {item.productId.name}
                        </h3>
                        <p className="text-gray-600">
                          Set your wardrobe with our Chrome Plus Jeans,
                          combining a sleek finish with premium stretch comfort.
                        </p>
                        <p className="text-gray-800 font-medium">
                          Price: ₹{item.productId.price}
                        </p>
                        <p className="text-gray-800 font-medium">
                          Size: {item.size}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className="w-full sm:w-1/3 p-6 shadow-lg rounded-lg bg-white"
                style={{ height: "250px" }} // Adjust height if needed
              >
                <h3 className="text-xl font-semibold mb-4">Price Summary</h3>

                <div className="flex flex-col h-full">
                  <div>
                    {/* Price of Items */}
                    <div className="flex justify-between font-semibold mt-3">
                      <p className="font-medium">{`Price (${
                        cartItems.length
                      } item${
                        cartItems.length > 1 ? "s" : ""
                      }) (incl. GST):`}</p>
                      <p className="font-medium">₹{totalPrice.toFixed(2)}</p>
                    </div>

                    {/* Delivery Charges */}
                    <div className="flex justify-between font-semibold">
                      <p className="font-medium">Delivery Charges:</p>
                      <p className="font-medium">
                        <s>₹ 100</s> Free
                      </p>
                    </div>

                    {/* GST Breakdown (Included in Price) */}
                    <div className="flex justify-between font-semibold mt-3">
                      <p className="font-medium">GST (12% Included):</p>
                      <p className="font-medium">
                        ₹{((totalPrice * 12) / 112).toFixed(2)}
                      </p>
                    </div>
                  </div>

                  {/* Subtotal positioned at the bottom inside the box */}
                  <div className="flex justify-between font-semibold mt-3 pt-4 border-t border-gray-300">
                    <p className="font-medium">Final Total:</p>
                    <p className="font-medium">₹{totalPrice.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container mx-auto bg-white p-6 rounded-lg shadow-lg mb-8">
              <div className="flex justify-between items-start">
                <h2 className="text-2xl font-semibold text-gray-700 mb-6 flex items-center">
                  Shipping Address and Payment Method
                </h2>

                {addressOption === "new" && (
                  <LocationButton setAddress={setAddress} />
                )}
              </div>

              <div className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="addressOption"
                    value="existing"
                    checked={addressOption === "existing"}
                    onChange={() => setAddressOption("existing")}
                    className="form-radio text-black"
                  />
                  <span className="ml-2">Use Existing Address</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    name="addressOption"
                    value="new"
                    checked={addressOption === "new"}
                    onChange={() => {
                      setAddressOption("new");
                      setShowSaveButton(true);
                      setShowFindMeButton(true);
                    }}
                    className="form-radio text-black"
                  />
                  <span className="ml-2">Add New Address</span>
                </label>
              </div>

              {addressOption === "existing" && (
                <div className="mb-4">
                  <div>
                    <label className="font-semibold">
                      Select Saved Address:
                    </label>
                    <div className="mt-2 flex flex-wrap gap-2 ">
                      {savedAddresses.map((addr, index) => (
                        <div
                          key={index}
                          className="flex items-start space-x-4 mb-2 cursor-pointer p-4 border rounded-lg w-full sm:w-64 h-40 relative"
                          onClick={() => handleAddressSelection(index)}
                        >
                          <input
                            type="radio"
                            checked={selectedAddressIndex === index}
                            onChange={() => {}}
                            className="h-5 w-5"
                          />
                          <div className="flex flex-col justify-between w-full">
                            <div className="flex justify-between items-start">
                              <p className="font-semibold text-lg">
                                {addr.fullName}
                              </p>
                              <button
                                className="text-blue-500 hover:text-blue-700 text-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsEditing(true);
                                  setEditingIndex(index);
                                  setNewAddressData({
                                    fullName: addr.fullName,
                                    phoneNumber: addr.phoneNumber,
                                    address: addr.address,
                                    city: addr.city,
                                    state: addr.state,
                                    postalCode: addr.postalCode,
                                  });
                                  setIsModalOpen(true);
                                }}
                              >
                                Edit
                              </button>
                            </div>
                            <p className="text-sm">{addr.address}</p>
                            <p className="text-sm">
                              {addr.city}, {addr.state} - {addr.postalCode}
                            </p>
                            <p className="text-sm">{addr.phoneNumber}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {addressOption === "new" && (
                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-4">
                    <label htmlFor="fullName" className="block font-medium">
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                      value={address.fullName}
                      onChange={handleAddressChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="phone" className="block font-medium">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={address.phone}
                      onChange={handleAddressChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="addressLine" className="block font-medium">
                      Address Line
                    </label>
                    <input
                      type="text"
                      id="addressLine"
                      name="addressLine"
                      value={address.addressLine}
                      onChange={handleAddressChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="city" className="block font-medium">
                      City
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={address.city}
                      onChange={handleAddressChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="state" className="block font-medium">
                      State
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      value={address.state}
                      onChange={handleAddressChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="postalCode" className="block font-medium">
                      Postal Code
                    </label>
                    <input
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      value={address.postalCode}
                      onChange={handleAddressChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>
                </div>
              )}

              <div className="mb-4">
                {showSaveButton && (
                  <button
                    onClick={saveAddressesToLocalStorage}
                    className="py-2 px-4 bg-black text-white rounded-md hover:bg-gray-800 mt-2"
                  >
                    Save Address
                  </button>
                )}
              </div>

              <div className="flex justify-center mt-6">
                {addressOption === "existing" && (
                  <button
                    onClick={initiateOrder}
                    className="py-2 px-6 bg-black text-white rounded-md hover:bg-gray-800"
                  >
                    Proceed to Payment
                  </button>
                )}
              </div>
            </div>

            {/* Address Modal */}
            {isModalOpen && (
              <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg w-full max-w-lg">
                  <h3 className="text-xl font-semibold text-gray-800 mb-4">
                    {isEditing ? "Edit Address" : "Add New Address"}
                  </h3>
                  <form>
                    <input
                      className="w-full p-2 mb-4 border border-gray-300 rounded"
                      type="text"
                      name="fullName"
                      value={newAddressData.fullName}
                      onChange={handleInputChange}
                      placeholder="Full Name"
                      required
                    />
                    <input
                      className="w-full p-2 mb-4 border border-gray-300 rounded"
                      type="text"
                      name="phoneNumber"
                      value={newAddressData.phoneNumber}
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                      required
                    />
                    <input
                      className="w-full p-2 mb-4 border border-gray-300 rounded"
                      type="text"
                      name="address"
                      value={newAddressData.address}
                      onChange={handleInputChange}
                      placeholder="Address"
                      required
                    />
                    <input
                      className="w-full p-2 mb-4 border border-gray-300 rounded"
                      type="text"
                      name="city"
                      value={newAddressData.city}
                      onChange={handleInputChange}
                      placeholder="City"
                      required
                    />
                    <input
                      className="w-full p-2 mb-4 border border-gray-300 rounded"
                      type="text"
                      name="state"
                      value={newAddressData.state}
                      onChange={handleInputChange}
                      placeholder="State"
                      required
                    />
                    <input
                      className="w-full p-2 mb-4 border border-gray-300 rounded"
                      type="text"
                      name="postalCode"
                      value={newAddressData.postalCode}
                      onChange={handleInputChange}
                      placeholder="Postal Code"
                      required
                    />
                    <div className="flex justify-between">
                      <button
                        type="button"
                        className="p-2 bg-gray-500 text-white rounded"
                        onClick={() => {
                          setIsModalOpen(false);
                          setIsEditing(false);
                          setNewAddressData({
                            fullName: "",
                            phoneNumber: "",
                            address: "",
                            city: "",
                            state: "",
                            postalCode: "",
                          });
                        }}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="p-2 bg-blue-500 text-white rounded"
                        onClick={
                          isEditing ? handleSaveEdit : handleAddNewAddress
                        }
                      >
                        {isEditing ? "Save Changes" : "Add Address"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </>
  );
};

export default BuyNowPage;
