import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Using FontAwesome icons
import "./User.css";
import Img from "../../Assets/images/compressimg/RoseNoirTee.webp";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // Regex for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validate = () => {
    let validationErrors = {};

    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      validationErrors.email = "Enter a valid email address";
    }

    if (!formData.password.trim()) {
      validationErrors.password = "Password is required";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error when user types
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => {
      console.log("Toggling password visibility:", !prevShowPassword); // Debugging log
      return !prevShowPassword;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/signin`,
        formData
      );

      if (response.status === 200) {
        const { token, user } = response.data;

        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));

        navigate("/");
        window.location.reload();
      }
    } catch (error) {
      setErrors({
        api: error.response?.data?.message || "Login failed. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-w-screen flex items-center justify-center px-5 py-5">
      <div
        className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: "1000px" }}
      >
        <div className="md:flex w-full">
          <div className="w-full md:w-1/2 py-10 px-5 md:px-10 bg-white flex flex-col justify-center">
            <div className="text-center mb-5">
              <h1 className="font-bold text-3xl text-gray-900">SIGN IN</h1>
              <p>Enter your information to login</p>
            </div>
            <form onSubmit={handleSubmit}>
              {/* Email Field */}
              <div className="mb-5">
                <label htmlFor="email" className="text-xs font-semibold px-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full px-4 py-2 border rounded-lg outline-none focus:border-black"
                  placeholder="johnsmith@example.com"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs">{errors.email}</p>
                )}
              </div>

              {/* Password Field */}
              <div className="mb-5 relative">
                <label
                  htmlFor="password"
                  className="text-xs font-semibold px-1"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    key={showPassword ? "text" : "password"} // Forces re-render
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className="w-full px-4 py-2 border rounded-lg outline-none focus:border-black"
                    placeholder="********"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none z-10 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.password && (
                  <p className="text-red-500 text-xs">{errors.password}</p>
                )}
              </div>

              {/* API Error Message */}
              {errors.api && (
                <p className="text-red-500 text-xs text-center">{errors.api}</p>
              )}

              {/* Submit Button */}
              <div className="text-center">
                <button
                  type="submit"
                  className="w-full bg-black text-white rounded-lg py-2 font-semibold"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Processing..." : "SIGN IN"}
                </button>
              </div>
            </form>

            {/* Sign Up Redirect */}
            <div className="text-center mt-5">
              <p className="text-gray-600">
                Don't have an account?{" "}
                <Link to="/register" className="text-blue-500">
                  Sign Up
                </Link>
              </p>
            </div>

            {/* Forgot Password */}
            <div className="text-center mt-2">
              <Link to="/forgot-password" className="text-blue-500">
                Forgot Password?
              </Link>
            </div>
          </div>

          {/* Image Section */}
          <div className="hidden md:block w-1/2 bg-gradient-to-r from-gray-800 via-gray-900 to-black">
            <img src={Img} alt="Login" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
