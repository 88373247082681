import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./User.css";
import Img from "../../Assets/images/compressimg/RoseNoirTee.webp";

const SignupOTP = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false); // for resend OTP button loading state
  const [error, setError] = useState("");
  const [otpStatus, setOtpStatus] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const password = location.state?.password;

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      nextInput && nextInput.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index]) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      prevInput && prevInput.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const otpValue = otp.join("");

    if (otpValue.length !== 6) {
      setError("Please enter a valid 6-digit OTP.");
      return;
    }

    setError("");
    setOtpStatus("Verifying OTP");
    setLoading(true);

    console.log("email: " + email + ", Type: " + typeof email);
    console.log("password: " + password + ", Type: " + typeof password);

    try {
      let otpNumber = Number(otpValue);
      console.log("here");
      console.log("Converted OTP: " + otpNumber + ", Type: "  + typeof otpNumber);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify-otp`,
        { email, otp: otpNumber, password }
      );

      if (response.status === 200) {
        setOtpStatus("OTP Verified");
        navigate("/login");
      }
    } catch (error) {
      setError("Invalid OTP. Please try again.");
      setOtpStatus("");
      console.error("Error verifying OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setResendLoading(true);
    setError("");

    try {
      const data = { email }; // Pass email as the parameter
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        data
      );

      if (response.status === 200) {
        setOtpStatus("OTP Sent Again");
        alert("OTP has been resent to your email.");
      }
    } catch (error) {
      setError("Failed to resend OTP. Please try again.");
      console.error("Error resending OTP:", error);
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <div className="min-w-screen flex items-center justify-center px-5 py-5">
      <div
        className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: "1000px" }}
      >
        <div className="flex flex-col md:flex-row w-full">
          <div
            className="w-full md:w-1/2 py-10 px-5 md:px-10 bg-white"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="text-center mb-10">
              <h1 className="font-bold text-3xl text-gray-900">OTP VERIFY</h1>
              <p>Enter the 6-digit OTP sent to your email</p>
            </div>

            <div>
              <div className="flex justify-center mb-5">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className="w-12 h-12 mx-2 text-center text-xl border-2 border-gray-300 rounded-lg focus:border-black outline-none"
                    placeholder="-"
                  />
                ))}
              </div>

              {error && <p className="text-red-500 text-xs">{error}</p>}

              <div className="flex -mx-3">
                <div className="w-full px-3 mt-5">
                  <button
                    onClick={handleSubmit}
                    className="block w-full max-w-xs mx-auto  from-gray-800 via-gray-900 to-black hover:bg-black-500 text-white rounded-lg px-3 py-3 font-semibold"
                    style={{ backgroundColor: "#191919" }}
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="dots-container">
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                      </span>
                    ) : otpStatus === "Verifying OTP" ? (
                      "Verifying OTP"
                    ) : otpStatus === "OTP Verified" ? (
                      "OTP Verified"
                    ) : (
                      "VERIFY OTP"
                    )}
                  </button>
                </div>
              </div>

              {/* Resend OTP Button */}
              <div className="text-center mt-4">
                <button
                  onClick={handleResendOtp}
                  className="text-blue-500 text-sm"
                  disabled={resendLoading}
                >
                  {resendLoading ? "Resending OTP..." : "Resend OTP"}
                </button>
              </div>
            </div>
          </div>

          <div className="hidden md:block w-1/2 bg-gradient-to-r from-gray-800 via-gray-900 to-black">
            <img
              src={Img}
              className="w-full h-auto rounded-lg object-cover"
              alt="OTP Verification"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupOTP;
