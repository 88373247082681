import { useState, useEffect } from "react";
import { X } from "lucide-react";
import Img from "../../Assets/images/Resized Images/Poup.jpg";
import { toast } from "react-hot-toast";
import { ClipLoader } from "react-spinners";

export default function Popup() {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    marketing: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAlreadySubscribed, setIsAlreadySubscribed] = useState(false);

  useEffect(() => {
    const popupDismissed = localStorage.getItem("popupDismissed");
    const subscribedEmail = localStorage.getItem("subscribedEmail");

    if (!popupDismissed && !subscribedEmail) {
      setIsOpen(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!formData.phone) {
      setError("Phone number is required.");
      setLoading(false);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const subscribeUrl = `${apiUrl}/newsletter/subscribe/`;

      const response = await fetch(subscribeUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phoneNumber: formData.phone.toString(),
          marketing: formData.marketing,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        // Check for "already subscribed" response from server
        if (
          data.message &&
          data.message.toLowerCase().includes("already subscribed")
        ) {
          setIsAlreadySubscribed(true);
          localStorage.setItem("subscribedEmail", formData.email);
          toast.success(
            "You're already subscribed! Thank you for your interest."
          );
          return;
        }
        throw new Error(
          data.message || "Subscription failed. Please try again."
        );
      }

      // Successful subscription
      localStorage.setItem("subscribedEmail", formData.email);
      setIsOpen(false);
      toast.success("Successfully subscribed to our newsletter!");
    } catch (err) {
      // Handle network errors or other unexpected errors
      const friendlyError = err.message.includes("already")
        ? "You're already subscribed!"
        : "Subscription failed. Please try again later.";

      setError(friendlyError);
      toast.error(friendlyError);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem("popupDismissed", "true");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white w-full max-w-3xl rounded-lg overflow-hidden relative">
        <button
          onClick={handleClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 z-10"
        >
          <X className="h-6 w-6" />
          <span className="sr-only">Close</span>
        </button>

        <div className="grid md:grid-cols-2 gap-0">
          <div className="hidden md:block">
            <img
              src={Img}
              alt="Fashion collection preview"
              className="w-full h-full object-cover"
            />
          </div>

          <div className="p-6">
            <div className="space-y-4 max-w-sm mx-auto">
              {isAlreadySubscribed ? (
                <div className="text-center space-y-4">
                  <h2 className="text-2xl font-bold text-gray-900">
                    You're Already Part of Our Community!
                  </h2>
                  <p className="text-sm text-gray-600">
                    Thank you for being a valued subscriber. You'll continue to
                    receive our latest updates and offers.
                  </p>
                  <button
                    onClick={handleClose}
                    className="w-full bg-black text-white py-2 rounded-lg font-semibold text-sm hover:bg-gray-800 transition-colors"
                  >
                    CONTINUE SHOPPING
                  </button>
                </div>
              ) : (
                <>
                  <div className="text-center space-y-2">
                    <h2 className="text-2xl font-bold text-gray-900">
                      Build a Divine Community with Us!
                    </h2>
                    <p className="text-sm text-gray-600">
                      Sign in now and be the first in line to grab our brand-new
                      collections! Get ahead of the crowd and indulge in divine
                      fashion before anyone else. Don't miss out!
                    </p>
                  </div>

                  <form onSubmit={handleSubmit} className="space-y-3">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        required
                        className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                        placeholder="Enter your name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        required
                        className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        required
                        className="w-full px-3 py-1.5 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                        placeholder="Enter your phone number"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="marketing"
                        name="marketing"
                        className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                        checked={formData.marketing}
                        onChange={handleInputChange}
                      />
                      <label
                        htmlFor="marketing"
                        className="ml-2 block text-sm text-gray-700"
                      >
                        I agree to receive marketing communications
                      </label>
                    </div>

                    <button
                      type="submit"
                      className="w-full bg-black text-white py-2 rounded-lg font-semibold text-sm transition-colors hover:bg-gray-800 disabled:opacity-50"
                      disabled={loading}
                    >
                      {loading ? (
                        <ClipLoader color="white" size={20} />
                      ) : (
                        "JOIN NOW"
                      )}
                    </button>

                    {error && (
                      <div className="text-center text-red-600 text-sm mt-2">
                        {error}
                      </div>
                    )}

                    <div className="text-center">
                      <button
                        type="button"
                        onClick={handleClose}
                        className="text-gray-600 text-sm hover:underline"
                      >
                        Maybe Later
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
