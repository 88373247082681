import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Using FontAwesome for better compatibility
import Img from "../../Assets/images/compressimg/RoseNoirTee.webp";

const SignupForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // Regex patterns
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]{10}$/;
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const validate = () => {
    let validationErrors = {};

    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    } else if (formData.name.length < 2) {
      validationErrors.name = "Name must be at least 2 characters";
    }

    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      validationErrors.email = "Enter a valid email";
    }

    if (!formData.phoneNumber.trim()) {
      validationErrors.phoneNumber = "Phone number is required";
    } else if (!phoneRegex.test(formData.phoneNumber)) {
      validationErrors.phoneNumber = "Enter a valid 10-digit phone number";
    }

    if (!formData.password.trim()) {
      validationErrors.password = "Password is required";
    } else if (!passwordRegex.test(formData.password)) {
      validationErrors.password =
        "Password must be at least 8 characters, include a number and a special character";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error when user types
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    setIsSubmitting(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        formData
      );

      navigate("/signup-otp-verify", {
        state: { email: formData.email, password: formData.password },
      });
    } catch (error) {
      setErrors({
        api:
          error.response?.data?.message || "Signup failed. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-w-screen flex items-center justify-center px-5 py-5">
      <div
        className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: "1000px" }}
      >
        <div className="md:flex w-full">
          {/* Left Section - Image */}
          <div className="hidden md:block w-1/2 bg-gradient-to-r from-gray-800 via-gray-900 to-black">
            <img
              src={Img}
              alt="Signup"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Right Section - Form */}
          <div className="w-full md:w-1/2 py-10 px-5 md:px-10 bg-white flex flex-col justify-center">
            <div className="text-center">
              <h1 className="font-bold text-3xl text-gray-900">REGISTER</h1>
              <p>Enter your details to create an account</p>
            </div>
            <form onSubmit={handleSubmit}>
              {/* Name Field */}
              <div className="mb-5">
                <label htmlFor="name" className="text-xs font-semibold px-1">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full px-4 py-2 border rounded-lg outline-none focus:border-black"
                  placeholder="John Doe"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <p className="text-red-500 text-xs">{errors.name}</p>
                )}
              </div>

              {/* Email Field */}
              <div className="mb-5">
                <label htmlFor="email" className="text-xs font-semibold px-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full px-4 py-2 border rounded-lg outline-none focus:border-black"
                  placeholder="example@email.com"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs">{errors.email}</p>
                )}
              </div>

              {/* Phone Number Field */}
              <div className="mb-5">
                <label
                  htmlFor="phoneNumber"
                  className="text-xs font-semibold px-1"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  className="w-full px-4 py-2 border rounded-lg outline-none focus:border-black"
                  placeholder="1234567890"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  pattern="[0-9]*"
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 text-xs">{errors.phoneNumber}</p>
                )}
              </div>

              {/* Password Field */}
              <div className="mb-5 relative">
                <label
                  htmlFor="password"
                  className="text-xs font-semibold px-1"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    key={showPassword ? "text" : "password"}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className="w-full px-4 py-2 border rounded-lg outline-none focus:border-black"
                    placeholder="********"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.password && (
                  <p className="text-red-500 text-xs">{errors.password}</p>
                )}
              </div>

              {/* Submit Button */}
              <div className="text-center">
                <button
                  type="submit"
                  className="w-full bg-black text-white rounded-lg py-2 font-semibold"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Processing..." : "REGISTER NOW"}
                </button>
              </div>
            </form>

            {/* Login Redirect */}
            <div className="text-center mt-5">
              <p className="text-gray-600">
                Already have an account?{" "}
                <Link to="/login" className="text-blue-500">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
