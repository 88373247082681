import React, { useState, useEffect } from "react";
import { Trash2 } from "lucide-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../Loader/Loader";
import cartEmpty from "../../Assets/images/empty-cart.png";
import { Link } from "react-router-dom";

// Skeleton loader component
const SkeletonLoader = ({ type }) => {
  switch (type) {
    case "cart-item":
      return (
        <div className="flex items-center space-x-4 border-b border-gray-300 pb-4 animate-pulse shadow-lg">
          <div className="w-32 h-32 bg-gray-200 rounded p-6 "></div>
          <div className="flex-1 space-y-4">
            <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
            <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
            <div className="h-4 w-2/3 bg-gray-200 rounded"></div>
            <div className="flex items-center justify-between">
              <div className="h-6 w-16 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      );
    case "price-summary":
      return (
        <div className="shadow-lg border-gray-300 p-4">
          <div className="animate-pulse">
            <div className="h-6 w-3/4 bg-gray-200 rounded mb-4"></div>
            <div className="h-4 w-full bg-gray-200 rounded mb-3"></div>
            <div className="h-4 w-full bg-gray-200 rounded mb-3"></div>
            <div className="h-4 w-full bg-gray-200 rounded mb-3"></div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

const Cart = (props) => {
  const navigate = useNavigate();
  const { startLoader, stopLoader } = useLoader();
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCartData();
  }, []);

  const fetchCartData = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData && storedUserData.isLoggedIn) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/cart/user/${storedUserData._id}`
        );
        if (response.data.status) {
          setCartItems(response.data.data.cart.items);
          setTotalPrice(response.data.data.totalPrice);
          let count = response.data.data.cart.items.reduce(
            (sum, item) => sum + item.quantity,
            0
          );
          props.setCartCount(count);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching cart data:", error);
        setIsLoading(false);
      }
    }
  };

  const updateQuantity = async (productId, itemId, size, action) => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      const userId = storedUserData?._id;

      await axios.put(
        `${process.env.REACT_APP_API_URL}/cart/update/${itemId}`,
        {
          userId,
          productId,
          size, // ✅ Fix: Sending size in the request
          action,
        }
      );

      fetchCartData(); // Refresh cart after update
    } catch (error) {
      console.error(`Error in ${action}Quantity:`, error);
    }
  };

  const deleteCartItem = async (itemId) => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      const userId = storedUserData?._id;
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/cart/delete/${itemId}`,
        { data: { userId } }
      );
      fetchCartData();
    } catch (error) {
      console.error("Error deleting cart item:", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="container-fluid text-center mt-20 mb-20">
          <div className="flex justify-between gap-10">
            {/* Left Box - Cart Items Skeleton */}
            <div className="w-full sm:w-1/2">
              <SkeletonLoader type="cart-item" />
            </div>

            {/* Right Box - Price Summary Skeleton */}
            <div className="w-full sm:w-1/3">
              <SkeletonLoader type="price-summary" />
            </div>
          </div>
        </div>
      ) : cartItems.length === 0 ? (
        <div
          className="container-fluid text-center  mt-20 mb-20"
          style={{ height: "80vh" }}
        >
          <img
            style={{ height: "auto", width: "100px" }}
            src={cartEmpty}
            className="mx-auto mb-4"
            alt="Cart Empty Icon"
          />
          <p className="text-xl font-semibold mb-2">
            You have no items to check out. Please add products to your cart
            before proceeding.
          </p>
          <button
            className="py-3 px-6 bg-black text-white rounded-md hover:bg-gray-800"
            onClick={() => (window.location.href = "/shop")}
          >
            Shop Now
          </button>
        </div>
      ) : (
        <div className="min-h-screen bg-gray-100 ">
          {/* Cart Items and Price Summary Above Address Form */}
          <div className="container mx-auto py-0 sm:py-10 flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-10 justify-center">
            {/* Left Box - Cart Items */}
            <div className="flex-1 p-6 bg-white shadow-lg rounded-lg flex flex-col sm:w-1/2 relative">
              <div className="flex-1 mb-10">
                {cartItems.map((item) => (
                  <div
                    key={item._id}
                    className="flex items-center space-x-4 border-b border-gray-300 pb-4"
                  >
                    <div>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}/${item.productId.images[0]}`}
                        alt={item.productId.name}
                        className="object-cover rounded mt-3"
                        style={{ height: "135px", width: "135px" }}
                      />
                      <div className="flex items-center justify-center mt-2 gap-2">
                        <button
                          onClick={() =>
                            updateQuantity(
                              item.productId._id,
                              item._id,
                              item.size,
                              "decrement"
                            )
                          }
                          className="px-4 py-2 bg-black text-white rounded-lg"
                        >
                          -
                        </button>

                        <div className="px-4 py-2 border border-gray-300 rounded-md">
                          <p className="text-lg">{item.quantity}</p>
                        </div>

                        <button
                          onClick={() =>
                            updateQuantity(
                              item.productId._id,
                              item._id,
                              item.size,
                              "increment"
                            )
                          }
                          className="px-4 py-2 bg-black text-white rounded-md"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="flex-1">
                      <Link
                        to={`/product-details/${item.productId._id}`}
                        className="text-black-500 hover:no-underline"
                      >
                        {item.productId.name}
                      </Link>
                      <p
                        className="text-gray-600"
                        style={{ textAlign: "justify", letterSpacing: "1px" }}
                      >
                        Set your wardrobe with our Chrome Plus Jeans, combining
                        a sleek finish with premium stretch comfort.
                      </p>
                      <p className="text-gray-800 font-medium">
                        Price: ₹{item.productId.price}
                      </p>
                      <div className="flex items-center justify-between mt-2">
                        <p className="text-sm">Size: {item.size}</p>
                        <button
                          className="p-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                          onClick={() => deleteCartItem(item._id)}
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* Checkout Button positioned at the bottom-right of the cart items box */}
              {cartItems.length > 0 && (
                <div className=" bottom-2 w-full sm:w-2/5 sm:ml-auto">
                  <button
                    onClick={() => (window.location.href = "/checkoutpage")}
                    className="w-full py-3 bg-black text-white rounded-md hover:bg-gray-800"
                  >
                    Checkout
                  </button>
                </div>
              )}
            </div>

            {/* Right Box - Price Summary */}
            <div
              className="w-full sm:w-1/3 p-6 shadow-lg rounded-lg bg-white"
              style={{ height: "250px" }} // Adjust height if needed
            >
              <h3 className="text-xl font-semibold mb-4">Price Summary</h3>

              <div className="flex flex-col h-full">
                <div>
                  {/* Price of Items */}
                  <div className="flex justify-between font-semibold mt-3">
                    <p className="font-medium">{`Price (${
                      cartItems.length
                    } item${cartItems.length > 1 ? "s" : ""}) (incl. GST):`}</p>
                    <p className="font-medium">₹{totalPrice.toFixed(2)}</p>
                  </div>

                  {/* Delivery Charges */}
                  <div className="flex justify-between font-semibold">
                    <p className="font-medium">Delivery Charges:</p>
                    <p className="font-medium">
                      <s>₹ 100</s> Free
                    </p>
                  </div>

                  {/* GST Breakdown (Included in Price) */}
                  <div className="flex justify-between font-semibold mt-3">
                    <p className="font-medium">GST (12% Included):</p>
                    <p className="font-medium">
                      ₹{((totalPrice * 12) / 112).toFixed(2)}
                    </p>
                  </div>
                </div>

                {/* Subtotal positioned at the bottom inside the box */}
                <div className="flex justify-between font-semibold mt-3 pt-4 border-t border-gray-300">
                  <p className="font-medium">Final Total:</p>
                  <p className="font-medium">₹{totalPrice.toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
