// components/FeedbackSection.js

import React, { useState, useEffect } from "react";
import axios from "axios";

const FeedbackSection = ({ productId }) => {
  const [submittedFeedback, setSubmittedFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");

  // Fetch feedback from the server (GET)
  const fetchFeedback = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/feedback/${productId}`
      );
      setSubmittedFeedback(response.data);
    } catch (error) {
      console.error("Error fetching feedback:", error);
    } finally {
      setLoading(false);
    }
  };

  // Updated submitFeedback function
  const submitFeedback = async () => {
    console.log(`productId ==> ${productId}`);
    console.log(`starRating ==> ${rating}`);
    console.log(`message ==> ${feedback}`);

    if (feedback.trim() === "" || rating === 0) {
      alert("Please provide a rating and feedback message.");
      return;
    }

    setFeedbackLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/feedback/feedback`, {
        productId,
        starRating: rating,
        message: feedback,
      });

      fetchFeedback(); // Refresh feedback after submitting
      setFeedback(""); // Clear the feedback input field
      setRating(0); // Reset the rating
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setFeedbackLoading(false);
    }
  };

  // Fetch feedback when the component mounts or productId changes
  useEffect(() => {
    if (productId) {
      fetchFeedback();
    }
  }, [productId]);

  return (
    <div className="feedback-section">
      <h3 className="text-lg font-medium mb-4">Customer Reviews</h3>

      {/* Review submission form */}
      <div className="mb-8 p-4 bg-gray-50 rounded-lg">
        <h4 className="text-md font-medium mb-3">Write a Review</h4>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Your Rating</label>
          <div className="flex space-x-1">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                onClick={() => setRating(star)}
                className={`text-2xl ${
                  rating >= star ? "text-yellow-500" : "text-gray-300"
                }`}
              >
                ★
              </button>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Your Review</label>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className="w-full p-3 border rounded focus:ring-2 focus:ring-black focus:border-transparent"
            rows="4"
            placeholder="Share your honest thoughts about this product..."
          />
        </div>

        <button
          onClick={submitFeedback}
          disabled={feedbackLoading}
          className="bg-black text-white py-2 px-6 rounded-md hover:bg-gray-800 disabled:bg-gray-400 transition-colors"
        >
          {feedbackLoading ? "SUBMITTING..." : "SUBMIT REVIEW"}
        </button>
      </div>

      {/* Customer reviews list */}
      {loading ? (
        <div className="text-center py-8">
          <p className="text-gray-500">Loading feedback...</p>
        </div>
      ) : submittedFeedback.length > 0 ? (
        <div className="space-y-6 overflow-y-auto max-h-64 pr-2">
          {" "}
          {/* Scrollable container after 4 reviews */}
          {submittedFeedback.map((item, index) => (
            <div
              key={index}
              className="border-b pb-6 last:border-b-0 transition-all ease-in-out duration-300"
            >
              <div className="flex items-center justify-between mb-2">
                {/* <div className="font-medium">
                  {item.userName || "Anonymous"}
                </div> */}
                <div className="flex">
                  {[...Array(5)].map((_, i) => (
                    <span
                      key={i}
                      className={`${
                        i < item.starRating
                          ? "text-yellow-500"
                          : "text-gray-300"
                      }`}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
              <p className="text-gray-600 mb-2">{item.message}</p>
              <div className="text-xs text-gray-400">
                {new Date(item.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-8">
          <p className="text-gray-500">
            No reviews yet. Be the first to share your thoughts!
          </p>
        </div>
      )}
    </div>
  );
};

export default FeedbackSection;
