import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

export default function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]); // State to store products
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderToCancel, setOrderToCancel] = useState(null);
  const [orderToReturn, setOrderToReturn] = useState(null);

  // Fetch products from API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/products/all"
        );
        const data = await response.json();
        if (Array.isArray(data)) {
          const formattedProducts = data.map((item) => ({
            id: item._id,
            name: item.name,
            price: item.price,
            image: process.env.REACT_APP_IMAGE_URL + "/" + item.images[0], // Assuming images[0] is the main image
          }));
          setProducts(formattedProducts);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  // Fetch orders for the user
  useEffect(() => {
    const fetchOrders = async () => {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      if (!storedUserData || !storedUserData._id) {
        console.error("User data or user ID not found in localStorage");
        setLoading(false);
        return;
      }

      const userId = storedUserData._id;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/orders/user/${userId}`
        );
        const data = await response.json();
        if (data.success) {
          setOrders(data.data);
        }
      } catch (error) {
        console.error("Error fetching orders", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  // Get product image by productId
  const getProductImage = (productId) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.image : "https://via.placeholder.com/150";
  };

  // Open modal for confirming cancellation
  const handleCancelClick = (orderId) => {
    setOrderToCancel(orderId);
    setIsModalOpen(true);
  };

  // Open modal for confirming return
  const handleReturnClick = (orderId) => {
    setOrderToReturn(orderId);
    setIsModalOpen(true);
  };

  // Confirm cancellation and send API request
  const handleCancelOrder = async () => {
    const updatedOrders = orders.filter((order) => order._id !== orderToCancel);
    setOrders(updatedOrders);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/orders/cancel/${orderToCancel}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = await response.json();

      if (data.success) {
        toast.success("Your order has been successfully canceled!");
      } else {
        toast.error(data.message || "Failed to cancel order!");
        setOrders(orders);
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error canceling order:", error);
      toast.error("An error occurred while canceling the order!");
      setOrders(orders);
      setIsModalOpen(false);
    }
  };

  // Confirm return and send API request
  const handleReturnOrder = async () => {
    const updatedOrders = orders.filter((order) => order._id !== orderToReturn);
    setOrders(updatedOrders);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/orders/return/${orderToReturn}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = await response.json();

      if (data.success) {
        toast.success("Your order has been successfully returned!");
      } else {
        toast.error(data.message || "Failed to return order!");
        setOrders(orders);
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error returning order:", error);
      toast.error("An error occurred while returning the order!");
      setOrders(orders);
      setIsModalOpen(false);
    }
  };

  // Close the modal without canceling or returning
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setOrderToCancel(null);
    setOrderToReturn(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto sm:px-6 ">
      <h1 className="text-3xl font-bold mt-12 md:mt-0 mb-0 md:mb-12 bg-clip-text text-black text-center">
        YOUR ORDERS
      </h1>

      <div className="space-y-8">
        {orders.map((order) => (
          <div
            key={order._id}
            className="bg-white p-8 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300"
          >
            {/* Order Header */}
            <div className="flex flex-wrap justify-between items-start gap-6 mb-3">
              <div>
                <div className="text-sm text-gray-500">
                  Order Date: {new Date(order.createdAt).toLocaleDateString()}
                </div>
                <h3 className="text-xl font-semibold text-gray-800">
                  Order Status : {order.orderStatus}
                </h3>
              </div>
              <div className="text-right">
                {order.orderStatus === "pending" && !order.canceled ? (
                  <button
                    className="text-sm text-white bg-red-500 hover:bg-red-600 px-4 py-2 rounded-full transition-colors duration-300"
                    onClick={() => handleCancelClick(order._id)}
                  >
                    Cancel
                  </button>
                ) : null}

                {order.orderStatus === "Delivered" && !order.returned ? (
                  <button
                    className="text-sm text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-full transition-colors duration-300"
                    onClick={() => handleReturnClick(order._id)}
                  >
                    Return
                  </button>
                ) : null}
              </div>
            </div>

            {/* Order Details */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-3">
              <div>
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  Shipping Address
                </h3>
                <div className="text-sm text-gray-600">
                  <p>{order.address.fullName}</p>
                  <p>{order.address.address}</p>
                  <p>
                    {order.address.city}, {order.address.state} -{" "}
                    {order.address.postalCode}
                  </p>
                  <p>Phone: {order.address.phoneNumber}</p>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  Payment Details
                </h3>
                <div className="text-sm text-gray-600">
                  <p>Payment Method: {order.paymentMethod}</p>
                  <p>Payment Status: {order.paymentStatus}</p>
                  <p>Total Price: ₹{order.totalPrice}</p>
                </div>
              </div>
            </div>

            {/* Order Items */}
            <div>
              <h3 className="text-lg font-semibold text-gray-800 mb-1">
                Order Items
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {order.items.map((item) => (
                  <div
                    key={item._id}
                    className="bg-gray-50 p-3 rounded-lg shadow-sm"
                    style={{ width: "160px" }}
                  >
                    <img
                      src={getProductImage(item.productId)}
                      alt={item.productName}
                      width={100}
                      height={150}
                      className="w-full h-48 object-cover rounded-lg mb-4"
                    />
                    <div className="text-sm text-gray-500">
                      <span className="font-semibold text-gray-700">
                        Product :{" "}
                      </span>
                      {item.productName}
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                      <div>
                        <span className="font-semibold text-gray-700">
                          Quantity :
                        </span>{" "}
                        {item.quantity}
                      </div>
                      <div className="ml-5">
                        <span className="font-semibold text-gray-700">
                          Size :
                        </span>{" "}
                        {item.size}
                      </div>
                    </div>

                    <div className="text-sm text-gray-500">
                      <span className="font-semibold text-gray-700">
                        Price :{" "}
                      </span>{" "}
                      ₹{item.price}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for Confirmation */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-xl shadow-lg w-11/12 sm:w-1/3">
            <h3 className="text-2xl font-semibold mb-6 text-center text-gray-800">
              Are you sure you want to {orderToCancel ? "cancel" : "return"}{" "}
              this order?
            </h3>
            <div className="flex justify-center gap-6">
              <button
                className="px-6 py-2 bg-gray-500 text-white rounded-full hover:bg-gray-600 transition-colors duration-300"
                onClick={handleCloseModal}
              >
                No
              </button>
              <button
                className="px-6 py-2 bg-red-600 text-white rounded-full hover:bg-red-700 transition-colors duration-300"
                onClick={orderToCancel ? handleCancelOrder : handleReturnOrder}
              >
                Yes, {orderToCancel ? "Cancel" : "Return"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
