import React from "react";
import { Link } from "react-router-dom";
import {
  FaPinterest,
  FaInstagram,
  FaWhatsapp,
  FaMapMarkerAlt,
} from "react-icons/fa";
import AD from "../../Assets/images/compressimg/footerlogo.png";

function Footer() {
  return (
    <div className="bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white py-5">
      <div className="container mx-auto px-6 md:px-12">
        {/* Footer Sections */}
        <div className="flex flex-wrap justify-between text-center md:text-left space-y-6 md:space-y-0">
          {/* Social Section */}
          <div className="w-full md:w-1/4">
            <p className="text-lg font-bold uppercase tracking-wide mb-2 ">
              Avant Divine
            </p>
            <ul className="space-y-3">
              <li>
                <a
                  href="/shop"
                  className="hover:text-gray-300 transition duration-200"
                >
                  Shop
                </a>
              </li>
              <li>
                <a
                  href="/aboutus"
                  className="hover:text-gray-300 transition duration-200"
                >
                  About Us
                </a>
              </li>
              {/* <li>
                <a
                  href="#terms"
                  className="hover:text-gray-300 transition duration-200"
                >
                  Terms of Service
                </a>
              </li> */}
            </ul>
          </div>

          {/* Info Section */}
          <div className="w-full md:w-1/4">
            <p className="text-lg font-bold uppercase tracking-wide mb-2">
              Info
            </p>
            <ul className="space-y-3">
              <li>
                <Link
                  to="/termsAndConditions"
                  className="hover:text-gray-300 transition duration-200"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/returnsandexchange"
                  className="hover:text-gray-300 transition duration-200"
                >
                  Returns & Exchange
                </Link>
              </li>
              <li>
                <Link
                  to="/shippinganddelivery"
                  className="hover:text-gray-300 transition duration-200"
                >
                  Shipping & Delivery
                </Link>
              </li>
              <li>
                <Link
                  to="/privacyPolicy"
                  className="hover:text-gray-300 transition duration-200"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Section */}
          <div className="w-full md:w-1/4">
            <p className="text-lg font-bold uppercase tracking-wide mb-2">
              Contact Us
            </p>
            <ul className="space-y-3">
              <li className="flex items-center justify-center md:justify-start gap-2">
                <a
                  href="https://alvo.chat/5kc9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2"
                >
                  <FaWhatsapp /> WhatsApp
                </a>
              </li>
              <li className="flex items-center justify-center md:justify-start gap-2">
                <a
                  href="https://www.instagram.com/avantdivine/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2"
                >
                  <FaInstagram />
                  Instagram
                </a>
              </li>

              <li className="flex items-center justify-center md:justify-start gap-2">
                <a
                  href="https://in.pinterest.com/avantdivine/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2"
                >
                  <FaPinterest /> {/* Pinterest icon */}
                  Pinterest
                </a>
              </li>
            </ul>
          </div>

          {/* Address Section */}
          <div className="w-full md:w-1/4">
            <p className="text-lg font-bold uppercase tracking-wide mb-2">
              Address
            </p>
            <p className="text-sm">
              <a href="https://maps.app.goo.gl/nWAbdA9J22dUkVpP9?g_st=iw">
                <FaMapMarkerAlt className="inline-block text-gray-300 mr-2" />
                Pinakin Building, Plot No.18/2, opposite IKEA, Sector-III, HUDA
                Techno Enclave, HITEC City, Hyderabad, Telangana 500081
              </a>
            </p>

            <p className="mt-3">Contact: 9000308811</p>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-2 pt-2 sm:pt-4 border-t border-gray-700 text-center">
          <div className="flex justify-center">
            <img src={AD} alt="Advertisement" className="w-48" />
          </div>
          <p className="text-gray-500 text-sm">
            &copy; {new Date().getFullYear()} Avant Divine. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
