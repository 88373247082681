import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sizegide from "../../Assets/images/Avant Divine Size Chart-01.png";
import { useLoader } from "../Loader/Loader";
import "./ProductDetails.css";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";
import _ from "lodash";
import FeedbackSection from "./FeedbackSection";

export default function ProductDetails(props) {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedImage, setSelectedImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [stock, setStock] = useState(0);
  const [activeTab, setActiveTab] = useState("PRODUCT INFORMATION");
  const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false);
  const { startLoader, stopLoader } = useLoader();
  const navigate = useNavigate();
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [wishlistLoading, setWishlistLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [autoSlideIndex, setAutoSlideIndex] = useState(0);
  const slideIntervalRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);
  const [submittedFeedback, setSubmittedFeedback] = useState([]);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/products/${productId}`
        );
        const data = await response.json();

        if (isMounted) {
          setProduct((prev) =>
            JSON.stringify(prev) !== JSON.stringify(data) ? data : prev
          );
          setIsInWishlist(data.isInWishlist || false);
          setStock(data.stock);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    if (productId) {
      fetchProduct();
    }
    fetchFeedback();

    return () => {
      isMounted = false;
    };
  }, [productId]);

  useEffect(() => {
    if (product?.images?.length > 1) {
      slideIntervalRef.current = setInterval(() => {
        if (!isHovering) {
          setAutoSlideIndex(
            (prevIndex) => (prevIndex + 1) % product.images.length
          );
        }
      }, 3000);
    }

    return () => {
      if (slideIntervalRef.current) {
        clearInterval(slideIntervalRef.current);
      }
    };
  }, [product, isHovering]);

  useEffect(() => {
    setSelectedImage(autoSlideIndex);
  }, [autoSlideIndex]);

  const fetchFeedback = async () => {
    setLoading(true); // Set loading to true before making the request
    console.log("I am here for feedback");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/feedback/${productId}`
      );
      console.log(response.data);
      setSubmittedFeedback(response.data); // Update the state with fetched feedback data
    } catch (error) {
      console.error("Error fetching feedback:", error);
    } finally {
      setLoading(false); // Set loading to false once the request is complete
    }
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleDotClick = (index) => {
    setAutoSlideIndex(index);
  };

  const handleWishlist = async () => {
    if (wishlistLoading) return;
    setWishlistLoading(true);

    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (!storedUserData?._id) {
      sessionStorage.setItem("redirectTo", window.location.pathname);
      window.location.href = "/login";
      return;
    }

    try {
      if (isInWishlist) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/wishlist/remove`, {
          data: { userId: storedUserData._id, productId },
        });
        setIsInWishlist(false);
        toast.success("Removed from wishlist");
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/wishlist/add`, {
          userId: storedUserData._id,
          productId,
          size: selectedSize || null,
          quantity: 1,
        });
        setIsInWishlist(true);
        toast.success("Added to wishlist");
      }
    } catch (error) {
      console.error("Error handling wishlist:", error);
      toast.error("Failed to update wishlist");
    } finally {
      setWishlistLoading(false);
    }
  };

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  const handleAddToCart = async (e) => {
    // Prevent the default form submission or button click behavior
    e.preventDefault();

    if (cartLoading) return;
    if (!selectedSize) {
      alert("Please select a size");
      return;
    }

    setCartLoading(true);
    // startLoader();
    try {
      const storedUserData = JSON.parse(localStorage.getItem("user"));
      if (!storedUserData?._id) {
        sessionStorage.setItem("redirectTo", window.location.pathname);
        window.location.href = "/login";
        return;
      }

      await fetch(`${process.env.REACT_APP_API_URL}/cart/add`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: storedUserData._id,
          productId: product._id,
          quantity,
          size: selectedSize,
        }),
      });

      props.setCartCount((prev) => prev + quantity);
      toast.success("Item added to cart!");
    } catch (error) {
      console.error("Error adding to cart:", error);
    } finally {
      setCartLoading(false);
      stopLoader();
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleBuyNow = async () => {
    if (!selectedSize) {
      alert("Please select a size");
      return;
    }

    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (!storedUserData?._id) {
      sessionStorage.setItem("redirectTo", window.location.pathname);
      window.location.href = "/login";
      return;
    }

    if (isLoading) return;
    setIsLoading(true);

    const cartItem = {
      userId: storedUserData._id,
      productId: product._id,
      quantity,
      size: selectedSize,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cart/add`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(cartItem),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
        return;
      }

      props.setCartCount((prevCount) => prevCount + quantity);

      if (typeof window.fbq === "function" && !window.purchaseEventFired) {
        window.fbq("track", "Purchase", {
          content_ids: [product._id],
          content_name: product.name,
          currency: "INR",
          value: product.price,
        });
        window.purchaseEventFired = true;
      }

      navigate(`/buynow/${productId}`);
    } catch (error) {
      console.error("Error adding item to cart:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleSizeGuide = () => {
    setIsSizeGuideOpen(!isSizeGuideOpen);
  };

  const handleSubmitFeedback = async () => {
    if (!feedback || rating === 0) {
      toast.error("Please provide both feedback and rating");
      return;
    }

    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (!storedUserData?._id) {
      sessionStorage.setItem("redirectTo", window.location.pathname);
      window.location.href = "/login";
      return;
    }

    setFeedbackLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/feedback`,
        {
          userId: storedUserData._id,
          productId,
          rating,
          comment: feedback,
          userName: storedUserData.name || "Anonymous",
        }
      );

      setSubmittedFeedback((prev) => [...prev, response.data]);
      setFeedback("");
      setRating(0);
      toast.success("Thank you for your review!");
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit review");
    } finally {
      setFeedbackLoading(false);
    }
  };

  if (!product)
    return (
      <div className="min-h-screen flex items-center justify-center">
        Loading...
      </div>
    );

  const sizes = product.sizes || [];
  const tabs = ["PRODUCT INFORMATION", "SHIPPING & RETURNS", "REVIEWS"];

  return (
    <div className="max-w-7xl mx-auto px-4 py-0 sm:py-8 grid grid-cols-1 lg:grid-cols-2 gap-0 sm:gap-8">
      <div className="relative">
        <div
          className="relative w-full"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="w-full overflow-hidden">
            {product.images &&
              product.images.map((img, index) => (
                <div
                  key={index}
                  className={`transition-opacity duration-500 ${
                    autoSlideIndex === index ? "block" : "hidden"
                  }`}
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}/${img}`}
                    alt={`Product Image ${index + 1}`}
                    className="w-full h-auto object-cover"
                  />
                </div>
              ))}
          </div>

          <div className="flex justify-center mt-4 space-x-2">
            {product.images &&
              product.images.map((_, index) => (
                <button
                  key={index}
                  onClick={() => handleDotClick(index)}
                  className={`w-3 h-3 rounded-full ${
                    autoSlideIndex === index ? "bg-black" : "bg-gray-300"
                  }`}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
          </div>

          <div className="hidden lg:flex gap-4 mt-4 overflow-x-auto">
            {product.images &&
              product.images.map((img, index) => (
                <button
                  key={index}
                  onClick={() => handleDotClick(index)}
                  className={`flex-shrink-0 w-20 h-20 border ${
                    autoSlideIndex === index
                      ? "border-black"
                      : "border-gray-200"
                  }`}
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}/${img}`}
                    alt={`Thumbnail ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </button>
              ))}
          </div>
        </div>
      </div>

      <div className="">
        <div className="flex justify-between items-baseline space-x-4 w-full">
          <h1 className="text-2xl font-medium">{product.name}</h1>
          <button
            onClick={handleWishlist}
            className={`flex items-center gap-2 text-lg transition-all ${
              isInWishlist ? "text-red-500" : "text-gray-600"
            }`}
            disabled={wishlistLoading}
          >
            {isInWishlist ? (
              <AiFillHeart size={24} />
            ) : (
              <AiOutlineHeart size={24} />
            )}
          </button>
        </div>

        <p className="text-gray-600 mt-2" style={{ textAlign: "justify" }}>
          {product.description}
        </p>

        <div className="mt-6">
          <h2 className="text-sm font-medium">Select Size</h2>
          <div className="flex gap-2 mt-2">
            {sizes.map((size) => (
              <button
                key={size}
                onClick={() => setSelectedSize(size)}
                className={`w-12 h-12 border ${
                  selectedSize === size
                    ? "border-black bg-black text-white"
                    : "border-gray-200 hover:border-black"
                }`}
              >
                {size}
              </button>
            ))}
          </div>
        </div>

        <div className="mt-2">
          <button
            onClick={handleToggleSizeGuide}
            className="text-sm text-blue-600 hover:underline"
          >
            Size Guide
          </button>
        </div>

        <div className="mt-6">
          <h2 className="text-sm font-medium mb-2">Quantity</h2>
          <div className="flex items-center gap-4">
            <button
              onClick={handleDecreaseQuantity}
              className="w-10 h-10 bg-gray-200 text-black text-lg flex justify-center items-center border border-gray-300 hover:bg-gray-300"
            >
              -
            </button>
            <p className="text-lg">{quantity}</p>
            <button
              onClick={handleIncreaseQuantity}
              className="w-10 h-10 bg-gray-200 text-black text-lg flex justify-center items-center border border-gray-300 hover:bg-gray-300"
            >
              +
            </button>
          </div>
        </div>

        <div className="mt-6 space-y-4">
          <p className="text-xl">
            ₹{(product.price * quantity).toLocaleString()}
          </p>

          {stock === 0 ? (
            <button className="w-full bg-[#14161A] text-white py-3 px-4 hover:bg-black transition-colors">
              SOLD OUT
            </button>
          ) : (
            <>
              <button
                onClick={handleAddToCart}
                disabled={cartLoading}
                className="w-full bg-[#14161A] text-white py-3 px-4 hover:bg-black transition-colors disabled:opacity-70"
              >
                {cartLoading ? "ADDING..." : "ADD TO CART "}
              </button>
              <button
                onClick={handleBuyNow}
                disabled={isLoading}
                className="w-full bg-red-600 text-white py-3 px-4 hover:bg-red-700 transition-colors mt-2 disabled:opacity-70"
              >
                {isLoading ? "PROCESSING..." : "BUY NOW"}
              </button>
            </>
          )}
        </div>

        <div className="mt-8">
          <div className="flex border-b">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`px-4 py-2 text-sm ${
                  activeTab === tab
                    ? "border-b-2 border-black"
                    : "text-gray-500"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="pt-4">
            {activeTab === "PRODUCT INFORMATION" ? (
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <p className="font-medium">Material</p>
                    <p className="text-gray-600">
                      {product.productInfo.material}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium">Weight</p>
                    <p className="text-gray-600">
                      {product.productInfo.weight}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium">Country of origin</p>
                    <p className="text-gray-600">
                      {product.productInfo.countryOfOrigin}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium">Dimensions</p>
                    <p className="text-gray-600">
                      {product.productInfo.dimensions}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium">Type</p>
                    <p className="text-gray-600">{product.productInfo.type}</p>
                  </div>
                </div>
              </div>
            ) : activeTab === "SHIPPING & RETURNS" ? (
              <div className="text-sm text-gray-600">
                <p>{product.shippingAndReturns}</p>
              </div>
            ) : (
              <FeedbackSection productId={productId} />
            )}
          </div>
        </div>
      </div>

      {isSizeGuideOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleToggleSizeGuide}
        >
          <div
            className="bg-white p-6 max-w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={handleToggleSizeGuide}
              className="absolute top-2 right-2 text-xl text-black"
            >
              ×
            </button>
            <img
              src={Sizegide}
              alt="Size Guide"
              className="max-w-full"
              style={{ width: "600px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
