import React, { useState } from "react";
import axios from "axios"; // Importing axios for API calls
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory

const SignupForm = ({ onLoginClick, onSignupComplete }) => {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState(""); // Track the name input field
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false);

  const navigate = useNavigate(); 

  // Handle the form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const phoneNumber = formData.get("phoneNumber"); 
    const password = formData.get("password");

    setIsSubmitting(true);
    console.log( `${process.env.REACT_APP_API_URL}/auth/register`);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        { name, email, phoneNumber, password } 
      );
      setIsSubmitting(false);
      console.log(`SIGNUP PAGE ==> ${response.data}`);
      setEmail(email);
      setPhone(phoneNumber); 
      onSignupComplete(phoneNumber); 
    } catch (error) {
      setIsSubmitting(false);
      setErrorMessage(error.response?.data?.message || "An error occurred.");
    }
  };

  return (
    <div className="w-full">
      <h2 className="text-3xl font-bold text-center mb-8">Create Account</h2>

      <p className="text-center text-sm text-gray-600 mb-6">
        Use your email for registration
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <input
            type="text"
            name="name" // Ensure the correct name attribute is used
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
            placeholder="Name"
          />
        </div>

        <div>
          <input
            type="email"
            name="email" // Ensure the correct name attribute is used
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
            placeholder="Email"
          />
        </div>

        <div>
          <input
            type="tel"
            name="phoneNumber" // Make sure this is 'phoneNumber' for backend validation
            required
            pattern="[0-9]{10}" // Basic validation for a 10-digit phone number
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
            placeholder="Phone Number"
          />
        </div>

        <div>
          <input
            type="password"
            name="password" // Ensure the correct name attribute is used
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-rose-500"
            placeholder="Password"
          />
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full bg-rose-500 text-white py-2 rounded-md hover:bg-rose-600 transition-colors"
        >
          {isSubmitting ? "Signing Up..." : "Sign Up"}
        </button>
      </form>

      {errorMessage && (
        <div className="mt-4 text-red-500 text-center">{errorMessage}</div>
      )}

      {isVerificationEmailSent && (
        <div className="mt-6">
          <p className="text-center text-gray-600 mb-8">
            A verification email has been sent to your email:{" "}
            <strong>{email}</strong>. Please check your inbox and follow the
            instructions to verify your account.
          </p>
        </div>
      )}

      <p className="mt-4 text-center">
        Already have an account?{" "}
        <button
          onClick={onLoginClick}
          className="text-rose-500 hover:text-rose-600"
        >
          Sign in
        </button>
      </p>
    </div>
  );
};

export default SignupForm;
