import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function CollectionPage() {
  const [showAll, setShowAll] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState("Genesis");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/products/all`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched products: ", data);
        setProducts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError("Failed to fetch products");
        setLoading(false);
      });
  }, []);

  const handleShowAll = () => {
    setShowAll(true);
  };

  const handleCollectionChange = (event) => {
    setSelectedCollection(event.target.value);
  };

  // Debugging
  console.log("Selected collection: ", selectedCollection);

  // Hardcode collection filtering for now (since no collection field exists in the API data)
  const filteredProducts = products.filter(
    (product) => "Genesis" === selectedCollection // Hardcoding as "Genesis"
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (filteredProducts.length === 0) {
    return <div>No products found for this collection.</div>;
  }

  return (
    <div className="min-h-screen bg-[#f8f8f8]">
      <main className="container mx-auto px-6 py-4">
        <h1
          className="text-3xl font-bold "
          style={{ marginBottom: "10px", fontFamily: "math" }}
        >
          {selectedCollection}
        </h1>

        <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-2 md:gap-4 lg:gap-12">
          {filteredProducts
            .slice(
              0,
              showAll || window.innerWidth >= 1024 ? filteredProducts.length : 4
            )
            .map((product) => {
              // Constructing the image URL for the product
              const imageUrl = `${process.env.REACT_APP_IMAGE_URL}/${product.images[0]}`;
              console.log("Image URL: ", imageUrl); // Check image URL
              return (
                <div key={product._id} className="group">
                  <Link
                    to={`/product-details/${product._id}`}
                    className="block"
                  >
                    <div className="relative aspect-[4/5] mb-4 bg-gray-100 overflow-hidden">
                      <img
                        src={imageUrl}
                        alt={product.name}
                        className="object-cover transition-transform duration-700 group-hover:scale-105"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-lg font-medium mb-1">
                          {product.name}
                        </h3>
                        <p className="text-gray-600">₹{product.price}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>

        {!showAll && window.innerWidth < 1024 && (
          <div className="text-center mt-8 lg:hidden">
            <button
              onClick={handleShowAll}
              className="bg-black text-white px-4 py-2 text-sm uppercase tracking-wider"
            >
              Discover More
            </button>
          </div>
        )}
      </main>
    </div>
  );
}
