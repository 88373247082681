import React, { useEffect, useState } from "react";
import { XCircle, ShoppingBag, ArrowRight } from "lucide-react";
import { Link, useLocation } from "react-router-dom"; // Using useLocation to access the query parameters

const FailedPayment = () => {
  // State to store the transaction data
  const [transactionData, setTransactionData] = useState(null);

  // Use useLocation hook to access the query parameters in the URL
  const location = useLocation();

  // Extract txnId from the query string
  const queryParams = new URLSearchParams(location.search);
  const txnId = queryParams.get("txnId"); // Extract the txnId from the query string

  useEffect(() => {
    if (!txnId) {
      console.error("Transaction ID not found in the URL");
      return; // If txnId is not found, do not proceed with the API call
    }

    // Fetch transaction data from the API using the txnId
    const fetchTransactionData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/payment/transactions/status/${txnId}`
        );
        const data = await response.json();
        setTransactionData(data); // Set the response data to the state
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    // Call the function to fetch transaction data
    fetchTransactionData();
  }, [txnId]); // The effect will run when the txnId changes

  // If transaction data is not available, show a loading message or an error message
  if (!transactionData) {
    return <div>Loading...</div>;
  }

  // Extract necessary data from the transaction
  const {
    paymentStatus,
    orderStatus,
    transactionDetails,
    _id: orderId,
  } = transactionData;
  const transactionId = transactionDetails?.data?.transactionId || "N/A";
  const amountInPaise = transactionDetails?.data?.amount || 0;

  // Convert amount from paise to INR
  const amountInINR = amountInPaise / 100;

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-6 rounded-xl shadow-lg">
        <div className="text-center">
          <XCircle className="mx-auto h-16 w-16 text-red-500" />
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Payment Failed
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Something went wrong with your payment. Please try again.
          </p>
        </div>
        <div className="mt-8 space-y-6">
          <div className="border-t border-b border-gray-200 py-4">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-3">
              <div>
                <dt className="text-sm font-medium text-gray-500">
                  Payment Status
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{paymentStatus}</dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500">
                  Order Status
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{orderStatus}</dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Transaction ID
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{transactionId}</dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Order ID</dt>
                <dd className="mt-1 text-sm text-gray-900">{orderId}</dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Amount (INR)
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  ₹{amountInINR.toFixed(2)}
                </dd>
              </div>
            </dl>
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <Link
              to="/"
              className="w-full sm:w-auto inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <ShoppingBag className="mr-2 h-5 w-5" />
              Continue Shopping
            </Link>
            <Link
              to="/order"
              className="w-full sm:w-auto inline-flex justify-center items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Track Order
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailedPayment;
