import React, { useEffect, useState } from "react";
import axios from "axios";
// import profilePhoto from "../../Assets/images/compressimg/profileimg.jpg";

export default function ProfilePicture() {
  const [profilePicture, setProfilePicture] = useState("");
  const [loading, setLoading] = useState(false); // To show loader during upload

  useEffect(() => {
    getProfilePicture();
  }, []);

  // Fetch current profile picture from server
  const getProfilePicture = () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));

    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/user/${storedUserData._id}`)
      .then((response) => {
        if (response.data.status === "true") {
          const userData = response.data.data;
          setProfilePicture(userData.profilePicture);
        }
      })
      .catch((error) => {
        console.error("Error fetching user details", error);
      });
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(URL.createObjectURL(file)); // Show preview immediately
      uploadFile(file); // Start uploading the file
    }
  };

  // Upload the file to the server
  const uploadFile = async (file) => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    const formData = new FormData();
    formData.append("profilePicture", file);

    setLoading(true); // Show loading indicator while uploading

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/user/edit/${storedUserData._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("I am here"); // This should appear now
      console.log(response.data.status);

      if (response.data.status === true) {
        console.log("Profile picture updated successfully");

        // Don't call getProfilePicture here as it's unnecessary if we already have the response
        setProfilePicture(response.data.data.profilePicture); // Update the profile picture after upload
      }
    } catch (error) {
      console.error("Error uploading the profile picture", error);
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  return (
    <div className="relative w-32 h-32">
      {/* Profile Image */}
      <img
        className="rounded-full border border-gray-100 shadow-sm w-full h-full object-cover"
        src={
          profilePicture
            ? `${process.env.REACT_APP_IMAGE_URL}${profilePicture}`
            : ""
        }
        style={{ display: profilePicture ? "block" : "none" }}
        alt="User Profile"
      />

      {/* Edit Button */}
      <button
        style={{ fontSize: "21px" }}
        className="absolute bottom-0 right-0 mb-2 mr-2 h-8 w-8 border-4 border-white rounded-full bg-white text-black flex items-center justify-center"
        onClick={() => document.getElementById("fileInput").click()} // Trigger file input
      >
        ✎
      </button>

      {/* Hidden File Input */}
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        style={{ display: "none" }} // Hide the input
        onChange={handleFileChange}
      />

      {/* Loader Indicator */}
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
          <div className="text-white">Uploading...</div>
        </div>
      )}
    </div>
  );
}
