import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../Loader/Loader";
import axios from "axios";
import "./Profile.css";
import { Link } from "react-router-dom";
import cartEmpty from "../../Assets/images/empty-cart.png";

export default function WishlistPage(props) {
  const [wishlist, setWishlist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deletingItem, setDeletingItem] = useState(null);
  const [totalWishlist, setTotalWishlist] = useState(0);
  const navigate = useNavigate();
  const { startLoader, stopLoader } = useLoader();
  const quantity = 1;

  useEffect(() => {
    fetchWishlist();
  }, []);

  const fetchWishlist = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData && storedUserData.isLoggedIn) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/wishlist/user/${storedUserData._id}`
        );
        const wishlistItems = response.data.data.wishlistItems || [];
        setWishlist(wishlistItems);
        setTotalWishlist(wishlistItems.length);
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Remove item from wishlist
  const removeFromWishlist = async (productId, size) => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData && storedUserData.isLoggedIn) {
      try {
        setDeletingItem(productId);
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/wishlist/remove`,
          {
            data: {
              userId: storedUserData._id,
              productId: productId,
              size: size,
            },
          }
        );

        if (response && response.status === 200) {
          setWishlist((prevWishlist) =>
            prevWishlist.filter((item) => item.productId._id !== productId)
          );
        }
      } catch (error) {
        console.error("Error removing item from wishlist:", error);
      } finally {
        setDeletingItem(null);
      }
    }
  };

  // Add item to cart
  const handleAddToCart = async (productID, size) => {
    startLoader();
    const storedUserData = JSON.parse(localStorage.getItem("user"));

    if (!storedUserData?._id) {
      navigate("/login");
      stopLoader();
      return;
    }

    const cartItem = {
      userId: storedUserData._id,
      productId: productID,
      size: size,
      quantity,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cart/add`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(cartItem),
        }
      );

      if (response && response.status === 200) {
        fetchWishlist();
        removeFromWishlist(productID, size);
        navigate("/cart");
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      alert("An error occurred.");
    } finally {
      stopLoader();
    }
  };

  if (isLoading) {
    return (
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {[...Array(6)].map((_, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-lg shadow-sm animate-pulse"
          >
            <div className="relative aspect-square mb-4 bg-gray-200"></div>
            <div className="w-3/4 h-6 bg-gray-200 rounded mb-2"></div>
            <div className="w-1/2 h-6 bg-gray-200 rounded mb-4"></div>
            <div className="w-full h-10 bg-gray-200 rounded"></div>
          </div>
        ))}
      </div>
    );
  }

  // If wishlist is empty
  if (wishlist.length === 0) {
    return (
      <div className="container text-center mt-20 mb-20">
        <img
          style={{ height: "auto", width: "100px" }}
          src={cartEmpty}
          className="mx-auto mb-4"
          alt="Wishlist Empty Icon"
        />
        <p className="text-xl font-semibold mb-2">
          Your wishlist is currently empty. Please add products to your
          wishlist.
        </p>
        <button
          className="py-3 px-6 bg-black text-white rounded-md hover:bg-gray-800"
          onClick={() => navigate("/shop")}
        >
          Shop Now
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-4xl">
      <p className="text-xl font-bold mb-0 uppercase sm:mb-6 mt-5 text-center">
        Wishlist for future purchases ({totalWishlist})
      </p>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {wishlist.map((item) => (
          <div key={item._id} className="bg-white p-4 rounded-lg shadow-sm">
            <div className="relative aspect-square mb-4">
              <Link to={`/product-details/${item.productId._id}`}>
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}/${item.productId.images[0]}`}
                  alt={item.productId.name}
                  className="object-cover rounded-md w-full h-full"
                />
              </Link>
              <button
                onClick={() =>
                  removeFromWishlist(item.productId._id, item.size)
                }
                disabled={deletingItem === item.productId._id}
                className="absolute top-2 right-2 w-8 h-8 bg-white rounded-full shadow flex items-center justify-center text-gray-600 hover:text-red-500"
              >
                {deletingItem === item.productId._id ? (
                  <div className="loader"></div>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                )}
              </button>
            </div>
            <Link
              to={`/product-details/${item.productId._id}`}
              className="text-black-500 hover:no-underline"
            >
              {item.productId.name}
            </Link>
            <div className="flex flex-col sm:flex-row text-gray-600 mb-4 justify-between">
              <div className="mr-2 text-black">
                ₹{item.productId.price.toFixed(2)}
              </div>
              {/* <p className="font-semibold text-black">Size : {item.size}</p> */}
            </div>

            {/* <button
              onClick={() => handleAddToCart(item.productId._id, item.size)}
              className="w-full bg-black text-white py-2 rounded hover:bg-gray-800"
            >
              Add to Cart
            </button> */}
          </div>
        ))}
      </div>
    </div>
  );
}
