import React from "react";
import { Link } from "react-router-dom"; // If you're using React Router
import Img from "../../Assets/images/Resized Images/Avant Devine8828.jpg";

export default function WinterCollection() {
  const FadedEchoTeeProductId = "67794ca4089f04967e68cdd4";

  return (
    <main className=" mt-5">
      <section className="grid md:grid-cols-2 grid-cols-1 ">
        {/* Image Section with Link */}
        <div className="relative w-full   p-4 md:p-0">
          <Link to={`/product-details/${FadedEchoTeeProductId}`}>
            <img
              src={Img}
              alt="Two models wearing pieces from the winter collection"
              className="object-cover w-full lg:h-[800px] sm:h-full"
              // style={{ height: "800px" }}
            />
          </Link>
        </div>

        {/* Content Section */}
        <div
          className="p-4 md:p-16 lg:p-10 flex flex-col justify-center"
          style={{ paddingTop: "0px" }}
        >
          <div className="" style={{ maxWidth: "auto" }}>
            <h1 className="text-3xl md:text-4xl lg:text-5xl mb-2 tracking-wide ">
              Faded Echo Tee
            </h1>

            <p
              className="text-gray-600  leading-relaxed mb-2 sm:mb-0   sm:[letter-spacing:normal] [letter-spacing:-0.5px]"
              style={{ textAlign: "justify" }}
            >
              The Faded Echo Tee is where minimalism meets character, with
              ultra-soft French terry cotton fabric and a faded print that adds
              depth and intrigue. This classic crew-neck tee offers effortless
              comfort and a touch of artistic expression, perfect for pairing
              with denim, joggers, or layering under a jacket. Its vintage
              appeal combined with modern versatility makes it a go-to piece for
              any occasion, whether you're hitting the town or keeping it
              low-key. A creation meticulously crafted by the artisans of Avant
              Divine, this tee is a bold, wearable statement of individuality.
            </p>

            <Link
              to={`/product-details/${FadedEchoTeeProductId}`}
              className="inline-block bg-black text-white mt-3 px-8 py-4 text-sm tracking-wider hover:bg-gray-900 transition-colors"
            >
              Shop Now
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}
